.logo-name {
  font-size: large;
  color: #24252a;
}

.menu-container {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bm-burger-button {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 15px;
}

.bm-burger-bars {
  background: rgb(225, 16, 51);
  border-radius: 5px;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
}

.text-color-special {
  color: rgb(225, 16, 51);
}

.bm-cross {
  background: black;
}

.bm-menu-wrap {
  background: white;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.bm-menu {
  height: 100%;
  width: 100%;
  color: black;
  padding: 100px 0 0 0;
}

.bm-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.menu-links {
  text-align: center;
  padding: 20px 30px;
  font-size: x-large;
  font-weight: 500;
  cursor: pointer;
}

.menu-doodle {
  position: absolute;
  bottom: 0;
  right: 0;
}

.menu-doodle img {
  width: 500px;
}

.menu-container a {
  text-decoration: none;
}

@media screen and (max-width: 700px) {
  .bm-menu {
    padding: 20px 0 0 0;
  }

  .menu-links {
    text-align: left;
  }

  .menu-doodle img {
    width: 350px;
  }
}
