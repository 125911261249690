.doodle-1 {
  height: 500px;
}

.doodle img {
  height: 100%;
  width: 100%;
}

.text-center {
  text-align: center;
}

.text-bold {
  font-weight: bold;
}

.text-weight-800 {
  font-weight: 800;
}

.text-large {
  font-size: large;
}

.text-x-large {
  font-size: x-large;
}

.text-xx-large {
  font-size: xx-large;
}

.margin-top-bottom-10 {
  margin: 10px 0;
}

.margin-top-bottom-20 {
  margin: 20px 0;
}

.margin-top-bottom-30 {
  margin: 30px 0;
}

.margin-top-bottom-40 {
  margin: 40px 0;
}

.margin-left-20 {
  margin: 0 0 0 20px;
}

.padding-left-right-15 {
  padding: 0 15px;
}

.crossed-out {
  text-decoration: line-through;
}

section {
  display: flex;
  justify-content: space-between;
}

.section-lists li {
  padding: 3px 0;
}

.section-content {
  display: grid;
  place-content: center;
}

.section-content > div:first-child {
  width: 350px;
}

.section-title {
  margin: 15px 0;
}

.section-title > div:nth-child(1) {
  font-size: small;
}

.section-title > div:nth-child(2) {
  font-size: x-large;
  font-weight: bold;
  color: rgb(225, 16, 51);
}

section > div {
  width: 50%;
}

section:nth-child(even) > div:first-child {
  order: 1;
}

.doodle-backgrounds {
  background-size: contain;
  background-repeat: no-repeat;
  height: 800px;
}

.doodle-backgrounds img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.form-group {
  display: flex;
  justify-content: space-between;
}

.form-group > div {
  width: 49%;
}

.project-title {
  font-weight: 500;
}

.project-description {
  margin: 10px 0;
}

.project-image {
  height: 200px;
  padding: 10px;
  cursor: pointer;
}

.project-image img {
  height: 100%;
  width: 100%;
  outline: 1px solid #c5c5c5;
  border-radius: 5px;
}

.tools-used-container {
  margin: 10px 0;
}

.tools-used-container > div:first-child {
  font-weight: 700;
}

footer {
  height: 100px;
  display: grid;
  place-content: center;
  font-weight: 500;
  color: gray;
}

footer > div:first-child {
  text-align: center;
  padding: 0 20px;
  font-size: small;
}

@media screen and (max-width: 700px) {
  section {
    flex-direction: column;
    justify-content: flex-start;
    padding: 200px 0 0 0;
  }

  .section-content {
    display: block;
    padding: 15px;
  }

  .section-content > div:first-child {
    width: 100%;
  }

  section > div {
    width: 100%;
    height: 50%;
  }

  .section-title {
    margin: 10px 0;
  }

  section:nth-child(odd) > div:first-child {
    order: 1;
  }

  .doodle-backgrounds {
    height: 300px;
    /* background-attachment: fixed; */
  }

  /* .doodle-backgrounds img {
    display: none;
  } */

  /* .doodle-background-1 {
    background-image: url("../Assets/selfie.png");
  }

  .doodle-background-2 {
    background-image: url("../Assets/Open\ Doodles\ -\ Stuff\ To\ Do.svg");
  }

  .doodle-background-3 {
    background-image: url("../Assets/Open\ Doodles\ -\ In\ a\ Hurry\ \(1\).svg");
  }

  .doodle-background-4 {
    background-image: url("../Assets/Open\ Doodles\ -\ Swift.svg");
  } */
}
