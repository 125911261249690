.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  background: #fff;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
